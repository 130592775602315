h3 {
  text-align: center;
  margin: 20px 0;
  color: #ffffff;
  font-weight: bold;
  
}

.carousel .card {
  margin: auto;
  max-width: 300px;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.carousel .card img {
  padding: 5px;
  width: 100%;
  height: auto;
  border-radius: 100%;
  box-shadow: 5px 5px 10px #ffffff, -5px -5px 10px #ffffff;
  border: none;
}

.carousel .card-footer {
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s ease;
  border: none;
  background-color: transparent;
}

.carousel .card-footer:hover {
  color: #ffc700;
}

.carousel {
  padding: 25px;
}

.carousel .card-title {
  font-size: 1.2rem;
  margin: 10px 0;
}

.carousel .card-text {

  font-size: 1rem;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
}



/* Media query for tablets (768px and above) */
@media screen and (min-width: 768px) {
  .carousel .card-text {
    min-width: 600px;
}
}
/* Media query for desktops (1024px and above) */
@media screen and (min-width: 1024px) {
  .carousel .card-text {
    min-width: 800px;
}
}

/* Media query for mobile screens (up to 480px) */
@media screen and (max-width: 480px) {
  .carousel .card-text {
    min-width: 375px;
}
}

.text-muted {
  font-weight: bold;
}

.carousel .card-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

a:hover {
  color: aliceblue;
}
