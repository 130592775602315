* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Added for better box model handling */
}
.carousel h3{
  font-size: 1.1rem;
}

.carousel {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  overflow: hidden; /* Prevents blackspace around the carousel */
}

.carousel img {
  border: 5px solid #ffffff;
  border-radius: 20px;
  width: 100%; /* Full width of the container */
  max-width: 800px; /* Constrain image on larger screens */
  height: 500px; /* Fixed height */
  object-fit: cover; /* Scale the image without distortion */
  display: block; /* No inline space below the image */
}
/* Media Query for Small Android Devices */
@media (max-width: 480px) {
  .carousel img {
    max-width: 100%; /* No constraint, fill the container fully */
    height: 225px; /* Reduce height for small screens */
    border-radius: 10px; /* Smaller border-radius for compact layout */
   
  }
}

/* Media Query for Tablets and Small Laptops (PC) */
@media (min-width: 768px) and (max-width: 1024px) {
  .carousel img {
    max-width: 100%; /* No constraint, take full container width */
    height: 400px; /* Reduce height slightly for mid-sized screens */
    border-radius: 15px; /* Adjust the border-radius for smoother look */
  }
}

/* Media Query for Larger Screens (Desktops, large laptops) */
@media (min-width: 1025px) {
  .carousel img {
    max-width: 800px; /* Constrain the image to 800px max-width */
    height: 500px; /* Keep fixed height for large screens */
    border-radius: 20px; /* Keep larger border-radius */
  }
}

.carousel-indicators [data-bs-target] {
  border-radius: 100%;
  width: 5px;
  height: 5px;
  margin-bottom: 25px;
}

/* Custom Control Styles */
/*
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: black;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1; 
}

.carousel-control-prev {
  left: -50px; 
}

.carousel-control-next {
  right: -50px; 
}

*/