 * {
   margin: 0;
   padding: 0;
 }


 .page h4 {
   text-align: center;
   color: #ffffff;
 }

 span {
   font-size: 500;
   font-weight: bold;
   padding: 0px 5px;
  }
  
  p span {
   color: #fff455;
   display: inline-block;
   position: relative;
 }