* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Better box model handling */
  font-family: Poppins;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.heading {
  text-align: center;
}

.main {
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  font-size: large;
}

.section_a {
  text-align: center;
  color: #ffffff;
}

h4 {
  color: #fff455;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .main {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    color: #ffffff;
    flex-wrap: wrap;
  }

  .main .left-side,
  .main .right-side {
    border: 5px solid #800000;
    border-radius: 20px;
  }

  .main .right-side {
    border: 5px solid #ffffff;
    border-radius: 20px;
  }
}

/* Add this to prevent any unwanted scroll or spacing issues */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Ensures no horizontal scrollbar */
}
