.section-1{
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 0px 10px 10px 0;
}
.left{
    background-color: #ffffff;
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    padding: 20px;
    overflow: hidden;
}

.right{
    background-color: #800000;
    font-size: 200;
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    margin: 10px 15px;
    border-radius: 10px;
    overflow: hidden;
}
.account {
  max-width: 190px;
  border: 1px solid #ffffff;
  display: flex;
  overflow-x: auto;
  border: 5px solid #ffffff;
  border-radius: 10px;
  justify-content: flex-start;
  cursor: pointer;
}

.account::-webkit-scrollbar {
  width: 1;
}

.account .item {
  border: 3px solid #ffffff;
  border-radius: 50px;
  margin: 10px;
  min-width: 70px;
  height: 70px;
  line-height: center;
  text-align: center;
  background-color: #ffffff;
  padding: 3px;
}

span{
  color: #800000;
}
.right input {
  max-width: 400px;
}

.right {
  max-width: 400px;
}

.input_data{
  color: #800000;
  background-color: transparent;
}


@media only screen and (max-width: 500px) {

  .right {
    max-width: 300px;
  }

  .right input {
    max-width: 250px;
  }
}

@media only screen and (max-width: 900px) {

  .section {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    color: #800000;
    flex-wrap: wrap;
  }
  
  .section .left , .right {
    border: 5px solid #ffffff;
    border-radius: 20px;
  }
  
  .section-1 {
    border: 5px solid #ffffff;
    border-radius: 20px;
  }

}