/* Footer.module.css */

.footer {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: -5px -7px 10px #F8EDED, 0 0 20px #F8EDED;
  background-color: #800000;
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
  margin-top: auto;
  z-index: 100;
}

.footerContent {
  max-width: 960px;
  margin: 0 auto;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer ul li {
  display: inline-block;
  margin-right: 20px;
}

.footer ul li a {
  color: #ffffff;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: underline;
}
