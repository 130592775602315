.select-button {
  position: relative;
  display: block;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.buttons {
  margin-top: 15px;
  background-color: #ffffff;
  color: #800000;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
  border: 2px solid transparent;
  border-radius: 15px;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  font-size: 20px;
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  
  border-radius: 0px 0px 15px 15px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: #ffffff;
  padding: 1px 20px;
  text-decoration: none;
  display: block;
  transition: 0.1s;
}

.dropdown-content a:hover {
  background-color: #ffffff;
}

.dropdown-content a:focus {
  background-color: #000000;
}

.dropdown-content #top:hover {
  border-radius: 0px 0px 0px 0px;
}

.dropdown-content #bottom:hover {
  border-radius: 0px 0px 13px 13px;
}

.select-button:hover .buttons {
  border-radius: 15px 15px 0px 0px;
}

.select-button:hover .dropdown-content {
  display: block;
  margin-top: 50px;
}


/*custom css*/

h5 {
  white-space: nowrap;
}