
.why-bihari-pooja-path {
    padding: 40px 0;
  }
  
  .icon-image {
    max-width: 200px; /* Adjust size as needed */
    height: auto;
    box-shadow: 5px 5px 10px #ffffff, -5px -5px 10px #ffffff;
    border-radius: 100%;
  }
  
  .card-title {
    font-weight: bold;
    margin-bottom: 15px;
    color: #ffffff;
  }
  
  .card-text {
    font-size: 0.9rem; 
    color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .card-title {
      font-size: 1.2rem; /* Adjust title size for smaller screens */
    }
  
    .card-text {
      font-size: 0.85rem; /* Adjust text size for smaller screens */
    }
  }
  
  h3{
    color: #fff455;
  }