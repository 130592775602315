@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

selection {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.heading {
  margin: 40px 0;
}

.content {
  margin: "10px 0";
}

.Text {
  box-sizing: border-box;
  display: flex;
  min-height: 20vh;
  align-items: center;
  justify-content: space-around;
  box-shadow: 5px 5px 10px #ffffff, -5px -5px 10px #ffffff;
  font-size: 15px;
}

h1 {
  font-size: 40px;
  font-weight: bold;
}

.content {
  position: relative;
}

.content h2 {
  color: #ffffff;  font-size: 5em;
  position: absolute;
  transform: translate(-50%, -50%);
}
h1{
  color: #fff455;
}

.content h2:nth-child(2) {
  color: #fff455;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {

  0%,
  100% {
    clip-path: polygon(0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%);
  }

  50% {
    clip-path: polygon(0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%);
  }
}




@media (max-width:900px) {
  .Text h1 {
    font-size: 30px;
  }

  .Text {
    min-height: 18vh;
  }

}

@media (max-width:900px) {
  .Text .content {
    font-size: 8px;
  }

  .Text {
    min-height: 18vh;
  }

}



@media (max-width:650px) {
  .Text h1 {
    font-size: 20px;
  }

  .Text {
    min-height: 15vh;
  }


}

@media (max-width:650px) {
  .Text .content {
    font-size: 7px;
  }

  .Text {
    min-height: 15vh;
  }

}


@media (max-width:450px) {
  .Text h1 {
    font-size: 15px;
  }

  .Text {
    min-height: 12vh;
  }


}

@media (max-width:450px) {
  .Text .content {
    font-size: 5px;
  }

  .Text {
    min-height: 12vh;
  }


}

@media (max-width:312px) {
  .Text h1 {
    font-size: 10px;
  }

  .Text {
    min-height: 10vh;
  }


}

@media (max-width:312px) {
  .Text .content {
    font-size: 2px;
  }

  .Text {
    min-height: 10vh;
  }


}
h1{
  color: #ffffff;
}