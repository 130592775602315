* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

.clearfix {
  clear: both;
}

a {
  text-decoration: none;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

a:hover {
  text-decoration: none;
  outline: none;
}